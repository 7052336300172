import React from "react";
import _ from "lodash";
import { InpText, Tag } from "./utilityComps.js";
import { cdelay, getv, nano } from "../utils/utils.js";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCheckSquare,
  faSquare,
  faSquareMinus,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { Mo, MoVariants } from "../utils/motion_helper.js";
import { AnimatePresence } from "framer-motion";

export const Input = ({
  id,
  setter,
  defaultValue,
  placeholder = "Enter",
  autoComplete = "on",
  show_btn = <>SET</>,
  cont_cn = "",
  inp_cn = "",
  btn_cn = "",
  bg_c = "bg-red-400",
  border_c = "border-red-400",
  keep_track = false,
  onBlur = null,
  type = "text",
}) => {
  useEffect(() => {
    // let val =
    // if(defaultValue!==)
    set_val_inp(id, defaultValue);
  }, [defaultValue]);
  return (
    <div className="flex flex-row justify-center">
      <div
        className={`
          rounded-md 
          h-min
          xs:border lg:border-2
          ${border_c}
          focus-within:shadow-lg
          focus-within:outline-none
          ease-in duration-300
          bg-dark
          ${cont_cn}
        `}
      >
        <div className="w-1"></div>
        <input
          className={`
          outline:none
          focus:outline-none
          text-center
          bg-dark
          text-white resp-p-1
          focus:${bg_c}
          ease-in duration-300
          ${inp_cn}

          m-0
          `}
          id={id}
          placeholder={placeholder}
          type={type}
          defaultValue={defaultValue}
          onKeyDown={(e) => e.keyCode == 13 && setter()}
          onBlur={onBlur || setter}
          autoComplete={autoComplete}
        />
        {show_btn !== false && (
          <button
            onClick={setter}
            className={twMerge(bg_c, `resp-p-1 pr-2 pl-2 `, btn_cn)}
          >
            {show_btn}
          </button>
        )}
      </div>
    </div>
  );
};

export const DateInput = ({
  id,
  setter,
  defaultValue,
  placeholder = "Enter",
  autoComplete = "on",
  show_btn = <>SET</>,
  cont_cn = "",
  inp_cn = "",
  btn_cn = "",
  bg_c = "bg-red-400",
  border_c = "border-red-400",
  keep_track = false,
}) => {
  const [date, set_date] = useState(undefined);
  useEffect(() => {
    // console.log(defaultValue, moment(new Date(defaultValue)).isValid());
    if (!defaultValue || nano(defaultValue) == 0) {
      return set_date(undefined);
    }
    if (moment(new Date(defaultValue)).isValid())
      set_date(new Date(defaultValue));
    else set_date(undefined);
  }, [defaultValue]);
  useEffect(() => {
    if (nano(date) == 0) {
      return set_date(undefined);
    }
    if (moment(new Date(date)).isValid())
      setter(moment(new Date(date)).toISOString());
  }, [date]);
  return (
    <div className="flex flex-row justify-center">
      <div
        className={twMerge(
          `
          rounded-md 
          h-min
          border-2
          focus-within:shadow-lg
          ease-in duration-300
          `,
          cont_cn,
          border_c
        )}
      >
        <div className="w-1"></div>
        <DatePicker
          id={id}
          showTimeSelect
          dateFormat="Pp"
          placeholderText={placeholder}
          autoComplete="off"
          className="bg-black text-center"
          value={() => moment(date).format("DD-MM-YYYY HH:mmA")}
          {...(!date ? {} : { selected: date })}
          onChange={(date) => set_date(date)}
        />
      </div>
    </div>
  );
};

export const TWDateInput = () => {
  return <></>;
};

export const extract_inp = (id, type = "txt") => {
  let el = document.getElementById(id);
  if (!el) return undefined;
  let val = el.value;
  if (!val) return undefined;
  if (type == "n") {
    val = parseFloat(val);
    if (!_.isNaN(val)) return val;
    else return undefined;
  }
  return val;
};
export const set_val_inp = (id, txt = "") => {
  let el = document.getElementById(id);
  if (!el) return;
  el.value = txt;
};

export const set_state_ob_inp = (init, set, id, path, type = "txt") => {
  try {
    let ob = _.cloneDeep(init);
    let val = extract_inp(id, type);
    _.set(ob, path, val);
    // console.log(ob);
    set(ob);
  } catch (err) {}
};
export const set_state_ob = (init, set, path, val) => {
  try {
    let ob = _.cloneDeep(init);
    _.set(ob, path, val);
    set(ob);
  } catch (err) {}
};

export const InpRangeWrap = ({ idd, fkey, filters, set_filters }) => {
  const id_gen = (k) => `_${fkey}_${idd}_${k}`;
  const type = getv(filters, `${idd}.type`) ?? [];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const label = getv(filters, `${idd}.label`) ?? "label";

  const label_cn =
    getv(filters, `${idd}.label_cn`) ??
    "text-acc0 italic bg-transparent font-digi resp-text--1";
  const border_cn = getv(filters, `${idd}.border_cn`) ?? "";

  const show_label = getv(filters, `${idd}.show_label`) ?? true;
  const autoComplete = getv(filters, `${idd}.autoComplete`) ?? "off";

  return (
    <div className="flex flex-row mb-1 flex-nowrap w-full items-center">
      {show_label && (
        <div className="flex flex-row mb-1 flex-wrap">
          <Tag className={twMerge(cn, color_fn("label"), label_cn)}>
            {label}
          </Tag>
        </div>
      )}
      <div className="flex flex-row mb-1 flex-wrap flex-1 items-center resp-gap-1">
        {[
          ["mi", "Min"],
          ["mx", "Max"],
        ].map(([k, txt]) => (
          <Input
            {...{
              id: id_gen(k),
              key: id_gen(k),
              show_btn: false,
              inp_cn: twMerge(
                "lg:w-[4rem] xs:w-[2.5rem] resp-text-0 resp-p-1",
                cn
              ),
              border_c: twMerge(border_cn),
              placeholder: txt,
              defaultValue: getv(filters, `${idd}.vals.${k}`),
              setter: () =>
                set_state_ob_inp(
                  filters,
                  set_filters,
                  id_gen(k),
                  `${idd}.vals.${k}`,
                  "n"
                ),
              autoComplete,
            }}
          />
        ))}
      </div>
    </div>
  );
};
export const InpOnlyWrap = ({
  idd,
  fkey,
  filters,
  set_filters,
  dir,
  placeholder = null,
  inptype = "n",
}) => {
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_cn = getv(filters, `${idd}.label_cn`) ?? "text-acc0";
  const id_gen = `_${fkey}_${idd}`;
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const border_cn = getv(filters, `${idd}.border_cn`) ?? " ";
  return (
    <div className="flex flex-row mb-1 flex-nowrap w-full items-center">
      <div className="flex flex-row mb-1 flex-wrap">
        <Tag className={twMerge(label_cn)}>{label}</Tag>
      </div>
      <div className="flex flex-row mb-1 flex-wrap flex-1 items-center">
        {
          <Input
            {...{
              id: id_gen,
              key: id_gen,
              show_btn: false,
              inp_cn: twMerge("w-[4rem] ", cn),
              border_c: twMerge(border_cn),
              placeholder: placeholder || "..",
              defaultValue: getv(filters, `${idd}.vals`),
              setter: () =>
                set_state_ob_inp(
                  filters,
                  set_filters,
                  id_gen,
                  `${idd}.vals`,
                  inptype
                ),
            }}
          />
        }
      </div>
    </div>
  );
};

export const InpSwitch = ({ idd, fkey, filters, set_filters }) => {
  const id_gen = `_${fkey}_${idd}`;
  const val = getv(filters, `${idd}.vals`);
  // console.log("InpSwitch", val);
  const txt = getv(filters, `${idd}.txt`);
  const cn = getv(filters, `${idd}.cn`);
  const onClick = () => set_state_ob(filters, set_filters, `${idd}.vals`, !val);
  return (
    <Tag {...{ onClick, className: `${cn} ${val ? "" : "bg-opacity-40"}` }}>
      {txt}
    </Tag>
  );
};

export const def_txt_fn = (o) => o;
export const def_color_fn = (o) => "bg-green-500";
export const InpOptionsTdHead = ({ idd, fkey, filters, set_filters }) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  const actives = getv(filters, `${idd}.vals`) ?? [];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const add = (o) => _.uniq([...actives, o]);
  const remove = (o) => _.uniq(actives.filter((i) => i !== o));
  return (
    <>
      {options.map((option) => {
        let active = actives.includes(option);
        const click = () => {
          let nops;
          if (type == "options") nops = !active ? add(option) : remove(option);
          if (type == "options-only-ar") nops = !active ? [option] : [];
          set_state_ob(filters, set_filters, `${idd}.vals`, nops);
        };
        return (
          <Tag
            key={`${fkey}-${idd}-${option}`}
            className={bg_active(color_fn(option), active) + " " + cn}
            onClick={click}
          >
            {txt_fn(option)}
          </Tag>
        );
      })}
    </>
  );
};
export const InpOptionsWrap = ({
  idd,
  fkey,
  filters,
  set_filters,
  // show_label = true,
}) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  let actives = getv(filters, `${idd}.vals`) ?? [];
  if (type == "options-only") actives = [actives];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const add = (o) => _.uniq([...actives, o]);
  const remove = (o) => _.uniq(actives.filter((i) => i !== o));
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_cn =
    getv(filters, `${idd}.label_cn`) ??
    "text-acc0 italic bg-transparent font-digi resp-text--1";
  const label_mini = getv(filters, `${idd}.label_mini`) ?? "label_mini";
  const show_label = getv(filters, `${idd}.show_label`) ?? true;

  const base_cn = getv(filters, `${idd}.base_cn`) ?? "";
  const cont_cn = getv(filters, `${idd}.cont_cn`) ?? "";
  const inner_cont_cn = getv(filters, `${idd}.inner_cont_cn`) ?? "";
  const active_cn =
    getv(filters, `${idd}.active_cn`) ??
    ((a, acode) => (a ? "" : "bg-opacity-20"));

  return (
    <div
      className={twMerge("flex flex-row mb-1 flex-nowrap items-start", cont_cn)}
    >
      {show_label && (
        <div className="flex flex-row mb-1 flex-wrap">
          <Tag className={twMerge(cn, color_fn("label"), label_cn)}>
            {label}
          </Tag>
        </div>
      )}
      <div
        className={twMerge(
          "flex flex-row mb-1 flex-wrap flex-1",
          inner_cont_cn
        )}
      >
        {options.map((option) => {
          let active = actives.includes(option);
          const click = () => {
            let nops;
            if (type == "options")
              nops = !active ? add(option) : remove(option);
            if (type == "options-only-ar") nops = !active ? [option] : [];
            if (type == "options-only") nops = !active ? option : undefined;
            set_state_ob(filters, set_filters, `${idd}.vals`, nops);
          };
          return (
            <Tag
              key={`${fkey}-${idd}-${option}`}
              className={twMerge(
                base_cn,
                cn,
                color_fn(option),
                active_cn(active, option)
              )}
              onClick={click}
            >
              {txt_fn(option)}
            </Tag>
          );
        })}
      </div>
    </div>
  );
};

export const InputInputText = ({
  idd,
  fkey,
  filters,
  set_filters,
  dir,
  placeholder = null,
}) => {
  const label = getv(filters, `${idd}.label`) ?? "label";
  const id_gen = `_${fkey}_${idd}`;
  // console.log({id_gen})
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const label_cn = getv(filters, `${idd}.label_cn`) ?? "";
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const inptype = getv(filters, `${idd}.inptype`) ?? "txt";
  return (
    <div className="flex flex-row mb-1 flex-nowrap w-full items-center">
      <div className="flex flex-row mb-1 flex-wrap">
        <Tag className={`${label_cn}`}>{label}</Tag>
      </div>
      <div className="flex flex-row mb-1 flex-wrap flex-1 items-center">
        {
          <InpText
            {...{
              id: id_gen,
              key: id_gen,

              placeholder: placeholder || "..",
              inpprops: { className: twMerge(cn) },

              setter: (v) => {
                if (inptype == "n") v = parseFloat(v);
                set_state_ob(filters, set_filters, `${idd}.vals`, v);
              },
            }}
          />
        }
      </div>
    </div>
  );
};

export const InpCheckboxWrap = ({
  idd,
  fkey,
  filters,
  set_filters,
  // show_label = true,
}) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  let actives = getv(filters, `${idd}.vals`) ?? [];
  if (type == "options-only") actives = [actives];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const add = (o) => _.uniq([...actives, o]);
  const remove = (o) => _.uniq(actives.filter((i) => i !== o));
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_mini = getv(filters, `${idd}.label_mini`) ?? "label_mini";
  const show_label = getv(filters, `${idd}.show_label`) ?? true;

  const base_cn = getv(filters, `${idd}.base_cn`) ?? "";
  const cont_cn = getv(filters, `${idd}.cont_cn`) ?? "";
  const inner_cont_cn = getv(filters, `${idd}.inner_cont_cn`) ?? "";
  const active_cn =
    getv(filters, `${idd}.active_cn`) ??
    ((a, acode) => (a ? "" : "bg-opacity-20"));

  const [expand, set_expand] = useState(false);

  const clear = () => {
    let nops = null;
    if (type == "checkbox") nops = [];
    if (type == "checkbox-only-ar") nops = [];
    if (type == "checkbox-only") nops = null;
    set_state_ob(filters, set_filters, `${idd}.vals`, nops);
  };
  const actives_n = actives?.length ?? 0;

  return (
    <div
      className={twMerge(
        "flex flex-col mb-1 flex-nowrap items-start border-b border-acc0/30",
        cont_cn
      )}
    >
      <div className="fr-sc w-full resp-p-2 mb-1 cursor-pointer">
        <div onClick={() => set_expand(!expand)} className="flex-1 ">
          <div className="flex flex-row mb-1 flex-wrap">
            <Tag className={`${cn} ${color_fn("label")}`}>{label}</Tag>
          </div>
        </div>
        {actives_n > 0 ? (
          <Tag
            onClick={() => {
              clear();
              setTimeout(() => {
                set_expand(false);
              }, 50);
            }}
            className="text-red-400 rounded-[100px] border border-red-400 resp-px-2 text-[10px]"
          >
            clear
          </Tag>
        ) : (
          <></>
        )}
        <Tag onClick={() => set_expand(!expand)} className="resp-text-1">
          <FontAwesomeIcon icon={expand ? faAngleUp : faAngleDown} />
        </Tag>
      </div>
      {
        <motion.div
          variants={MoVariants.show_hide}
          animate={expand ? "visible" : "hidden"}
          className={twMerge(
            "bg-dark border-x-4 rounded-md border-x-reg pl-4 w-full",
            inner_cont_cn
          )}
        >
          {options.map((option) => {
            let active = actives.includes(option);
            const click = () => {
              let nops;
              if (type == "checkbox")
                nops = !active ? add(option) : remove(option);
              if (type == "checkbox-only-ar") nops = !active ? [option] : [];
              if (type == "checkbox-only") nops = !active ? option : undefined;
              set_state_ob(filters, set_filters, `${idd}.vals`, nops);
            };
            return (
              <Tag
                key={`${fkey}-${idd}-${option}`}
                className={twMerge(
                  "fr-sc resp-gap-2",
                  base_cn,
                  cn,
                  color_fn(option),
                  active_cn(active, option)
                )}
                onClick={click}
              >
                <FontAwesomeIcon
                  icon={active ? faCheckSquare : faSquareMinus}
                />
                <span>{txt_fn(option)}</span>
                <div className="flex-1"></div>
              </Tag>
            );
          })}
        </motion.div>
      }
    </div>
  );
};
export const bg_active = (c, active) =>
  `${c} ${active ? "border-2 border-white" : "bg-opacity-30"}`;

export const InpDatesWrap = ({ idd, fkey, filters, set_filters }) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  const actives = getv(filters, `${idd}.vals`) ?? [];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const label_cn = getv(filters, `${idd}.label_cn`) ?? " ";
  const border_cn = getv(filters, `${idd}.border_cn`) ?? " ";
  const add = (o) => _.uniq([...actives, o]);
  const remove = (o) => _.uniq(actives.filter((i) => i !== o));
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_mini = getv(filters, `${idd}.label_mini`) ?? "label_mini";
  const id_gen = (k) => `_${fkey}_${idd}_${k}`;
  return (
    <div className="flex flex-row mb-1 flex-nowrap w-full items-center">
      <div className="flex flex-row mb-1 flex-wrap">
        <Tag className={twMerge(label_cn)}>{label}</Tag>
      </div>
      <div className="flex flex-row mb-1 flex-wrap flex-1 items-center">
        {[
          ["mi", "Start Date"],
          ["mx", "End Date"],
        ].map(([k, txt]) => (
          <DateInput
            {...{
              id: id_gen(k),
              key: id_gen(k),
              show_btn: false,
              inp_cn: twMerge("w-full", cn),
              border_c: twMerge(border_cn),
              placeholder: txt,
              defaultValue: getv(filters, `${idd}.vals.${k}`),
              setter: (val) =>
                set_state_ob(filters, set_filters, `${idd}.vals.${k}`, val),
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const InpDatesRange = ({ idd, label, init, set }) => {
  const id_gen = (k) => `_${idd}_${k}`;
  const cn = "bg-dark text-white";
  return (
    <div className="flex flex-row mb-1 flex-nowrap w-full items-center">
      <div className="flex flex-row mb-1 flex-wrap">
        <Tag className={`${cn}`}>{label}</Tag>
      </div>
      <div className="flex flex-row mb-1 flex-wrap flex-1 items-center">
        {[
          ["st", "Start Date"],
          ["ed", "End Date"],
        ].map(([k, txt], idx) => (
          <React.Fragment key={id_gen(k)}>
            <DateInput
              {...{
                id: id_gen(k),
                show_btn: false,
                inp_cn: twMerge("w-full", "cn"),
                border_c: twMerge("cn"),
                placeholder: txt,
                defaultValue: getv(init, `${k}`),
                setter: (val) => set_state_ob(init, set, `${k}`, val),
              }}
            />
            {idx == 0 && <Tag>to</Tag>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export const InpAutoWrap = (props) => {
  const type = getv(props, `filters.${props.idd}.type`) ?? null;
  if (type == "options") return <InpOptionsWrap {...props} />;
  if (type == "options-only-ar") return <InpOptionsWrap {...props} />;
  if (type == "options-only") return <InpOptionsWrap {...props} />;
  if (type == "checkbox") return <InpCheckboxWrap {...props} />;
  if (type == "checkbox-only-ar") return <InpCheckboxWrap {...props} />;
  if (type == "checkbox-only") return <InpCheckboxWrap {...props} />;
  if (type == "date-range") return <InpDatesWrap {...props} />;
  if (type == "range") return <InpRangeWrap {...props} />;
  if (type == "only") return <InpOnlyWrap {...props} />;
  if (type == "inptext") return <InpOnlyWrap {...props} type="txt" />;
  if (type == "inputtext") return <InputInputText {...props} type="txt" />;
  return <></>;
};

export const LabInpWrap = ({
  tagw = "",
  id,
  init,
  set,
  k,
  placeholder,
  tlabel,
  ...props
}) => {
  return (
    <div className="flex flex-row mb-1 items-center flex-wrap">
      <Tag className={`${tagw}`}>{tlabel}</Tag>
      <Input
        {...{
          ...props,
          id: id,
          placeholder: placeholder,
          setter: () => set_state_ob_inp(init, set, id, k),
          defaultValue: getv(init, k),
          show_btn: false,
        }}
      />
    </div>
  );
};

export const LabInpWrapS = ({
  tagw = "",
  id,
  init,
  set,
  placeholder,
  tlabel,
  ...props
}) => {
  return (
    <div className="flex flex-row mb-1 items-center">
      <div className={`${tagw}`}>{tlabel}</div>
      <Input
        {...{
          ...props,
          id: id,
          placeholder: placeholder,
          setter: () => set(extract_inp(id)),
          defaultValue: init,
          show_btn: false,
          keep_track: true,
        }}
      />
    </div>
  );
};

export const blur_all_inps_in = async (sel) => {
  await cdelay(500);
  let els = document.querySelectorAll(`${sel} input`);
  // console.log(els);
  Array.from(els).map((e) => e.blur());
};

export const InpDropdown = ({
  options,
  setter,
  txt,
  text_fn = (o) => o,
  is_active = () => {},
  selection_cn = "",
  drop_cn = "",
  active_cn = "",
  option_cn = "",
}) => {
  const [open, set_open] = useState(false);
  return (
    <div className="relative">
      <Tag
        onClick={() => set_open(!open)}
        className={twMerge(
          "resp-px-2 text-white bg-dark xs:w-[5rem] lg:w-[10rem] flex flex-row justify-start items-center resp-text--2",
          selection_cn
        )}
      >
        <span>{txt ?? "--Select--"}</span>
        <div className="flex-1"></div>
        <FontAwesomeIcon icon={faAngleDown} />
      </Tag>
      <div className="absolute botom-5 min-w-max w-[10rem] z-[1000]">
        <AnimatePresence>
          {open && (
            <motion.div
              {...Mo.expand()}
              className={twMerge("rounded-md bg-slate-800 text-white", drop_cn)}
            >
              {options.map((o) => {
                return (
                  <motion.div
                    onClick={() => {
                      setter(o);
                      set_open(false);
                    }}
                    className={twMerge(
                      "resp-px-2 hover:bg-black/10 resp-text--2",
                      is_active(o) ? twMerge("bg-lig", active_cn) : "",
                      option_cn
                    )}
                  >
                    {text_fn(o)}
                  </motion.div>
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
