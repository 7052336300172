import React, { useContext, useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import qs from "query-string";
import { createContext } from "react";
import { MetaMaskWrapper } from "./wrappers/MetaMaskWrapper.js";
import HomePage from "./views/HomePage.js";
import Layout from "./components/Layout.js";
import AuthWrapper from "./wrappers/AuthWrapper.js";
import Login from "./views/Login.js";
import NoColor from "./wrappers/NoColor.js";
import AccountWrapper from "./wrappers/AccountWrapper.js";
import { useQueries } from "react-query";
import { q_token_prices } from "./queries/queries.js";
import { getv } from "./utils/utils.js";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Vault from "./views/VaultPage.js";
import { MC_Page } from "./views/DNA_MC_Page.js";
import LedgerPage from "./views/LedgerPage.js";
import Allowance from "./views/Allowance.js";
import AdminLedger from "./views/AdminLedger.js";

export const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export const NowContext = createContext({ now: null });
export const useNowContext = () => useContext(NowContext);

export const tokdecn = (token) =>
  token == "WETH" ? 6 : token == "DEZ" ? 0 : (token = "USD" ? 2 : 0);

function App() {
  const history = useNavigate();
  const location = useLocation();
  const psearch = qs.parse(location.search);

  const upd_psearch = (upd) => {
    let se = qs.stringify({ ...psearch, ...upd }, { arrayFormat: "bracket" });
    history(`${location.pathname}?${se}`);
  };

  const [now, set_now] = useState(Date.now());
  useEffect(() => {
    setTimeout(() => set_now(Date.now()), 200);
  }, [now]);

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const tokmap = useMemo(
    () => getv(qo_tokpri, "data.result"),
    [qo_tokpri.dataUpdatedAt]
  );

  const tokkey = (c) => (c == "WETH" ? "ethusd" : c == "DEZ" ? "dezusd" : null);
  const tok_to_usd_val = (cost, token) => {
    let k = tokkey(token);
    if (!k) return null;
    let tokp = tokmap[k];
    return parseFloat(tokp) * parseFloat(cost);
  };
  const usd_to_tok_val = (cost, token) => {
    let k = tokkey(token);
    if (!k) return null;
    let tokp = tokmap[k];
    return parseFloat(tokp) * parseFloat(cost);
  };

  const appcon = {
    history,
    location,
    path: location.pathname,
    psearch,
    upd_psearch,

    tokmap,
    tokdecn,
    tok_to_usd_val,
    usd_to_tok_val,
  };

  const maintenance = false;

  return (
    <div className="App bg-dark text-white">
      <NowContext.Provider value={{ now }}>
        <AppContext.Provider value={appcon}>
          <MetaMaskWrapper>
            <AuthWrapper>
              <AccountWrapper>
                <>
                  <Layout>
                    <HelmetProvider>
                      <Helmet>
                        <title>{"FairEx"}</title>
                      </Helmet>

                      {maintenance ? (
                        <div className="fc-cc text-red-300 my-[30vh] resp-gap-2">
                          <FontAwesomeIcon
                            className="text-[3rem]"
                            icon={faTriangleExclamation}
                          />
                          <p className="resp-text-2">App Under Maintenance</p>
                          <p className="resp-text-0">We will be back soon</p>
                        </div>
                      ) : (
                        <Routes>
                          <Route path="/" exact element={<HomePage />} />
                          <Route
                            path="/admin/bets"
                            exact
                            element={<AdminLedger />}
                          />
                          <Route path="/acc/:vault" exact element={<Vault />} />
                          <Route
                            path="/allowance"
                            exact
                            element={<Allowance />}
                          />
                          <Route
                            path="/dnamc/:rid"
                            exact
                            element={<MC_Page />}
                          />
                          <Route
                            path="/bets/:vault"
                            exact
                            element={<LedgerPage />}
                          />
                        </Routes>
                      )}
                    </HelmetProvider>
                  </Layout>
                </>
              </AccountWrapper>
            </AuthWrapper>
          </MetaMaskWrapper>
          <NoColor />
        </AppContext.Provider>
      </NowContext.Provider>
    </div>
  );
}

export default App;
